<template>
  <IxVectorLayer
    v-if="sensorData"
    :features="features"
    :vector-style="style"
    :z-index="1"
    layer-id="sensorData"
    auto-focus
  />
</template>

<script>
import convex from '@turf/convex'
import {Style, Fill, Stroke} from 'ol/style'

import {featureCollection} from '@turf/helpers'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    sensorData: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    features () {
      if (!this.sensorData) return []
      var result = convex(this.sensorData)
      return featureCollection([result])
    },
    style () {
      return new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 150, 0.3)'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 255, 0, 1)',
          width: 1
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
