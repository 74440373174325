<template>
  <BsCheckboxList
    v-if="options.length > 0" v-model="selectedAttributes"
    horizontal show-toggle-all
    compact :options="options"
  >
    <template #default="{option}">
      {{ option }}
    </template>
    <template #label>
      <IxRes>phbb.upload.attributes</IxRes>
    </template>
  </BsCheckboxList>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import BsCheckboxList from '@components/bootstrap/BsCheckboxList'

export default {
  components: {
    BsCheckboxList
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.upload.selectedAttributes',
      'data.upload.sensorData'
    ]),
    options () {
      if (!this.sensorData) return []
      return Object.keys(this.sensorData.features[0].properties).filter(x => x !== 'filename')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
