<template>
  <div>
    <FrsRouterBackLink :target="'phbb'">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>

    <ContentWrapper>
      <template #title>
        <IxRes>phbb.upload.title</IxRes>
      </template>

      <IxButton
        add large
        @click="showModal = true"
        @load="onLoad"
      >
        <IxRes>phbb.upload.select.sensorData</IxRes>
      </IxButton>

      <HelpBox v-if="fieldStates.fileSelected === 'error'" type="danger">
        <IxRes>phbb.upload.select.sensorData</IxRes>
      </HelpBox>

      <GeometryImportModal v-model="showModal" @load="onLoad" />

      <UploadMapLayer :sensor-data="sensorData" />

      <div v-if="sensorDataFileName">
        {{ sensorDataFileName }}
      </div>

      <AttributeSelection />

      <BoolSwitch v-model="entireField">
        <IxRes>phbb.upload.entireField</IxRes>
      </BoolSwitch>
      <HelpBox>
        <IxRes>phbb.upload.descriptions.entireField</IxRes>
      </HelpBox>
      <br>

      <VueDateTimePicker
        v-model="mappingDate"
        format="L" iso
        name="date" required
      >
        <template #label>
          <IxRes>phbb.upload.date</IxRes>
        </template>
      </VueDateTimePicker>

      <LabelAndMessage>
        <template #label>
          <IxRes>phbb.upload.method.title</IxRes>
        </template>
        <BsRadio v-model="method" value="standard">
          <IxRes>phbb.upload.method.standard</IxRes>
        </BsRadio>
        <BsRadio v-model="method" value="customized">
          <IxRes>phbb.upload.method.customized</IxRes>
        </BsRadio>
        <NumericInput
          v-model="blockSize" :rule="min(1)"
          placeholder="Blocksize"
          unit="m" name="blockSize"
          required :readonly="method === 'standard'"
        >
          <template #label>
            <IxRes>phbb.upload.blockSize</IxRes>
          </template>
        </NumericInput>
        <HelpBox>
          <IxRes>phbb.upload.descriptions.blocksize</IxRes>
        </HelpBox>
        <NumericInput
          v-model="pixelSize" :rule="min(1)"
          placeholder="Pixelsize"
          unit="m" name="pixelSize"
          required :readonly="method === 'standard'"
        >
          <template #label>
            <IxRes>phbb.upload.pixelSize</IxRes>
          </template>
        </NumericInput>
        <HelpBox>
          <IxRes>phbb.upload.descriptions.pixelsize</IxRes>
        </HelpBox>
      </LabelAndMessage>

      <IxButton large @click="launchMapManagement">
        <IxRes>phbb.wizard.button.launchMapManagement</IxRes>
      </IxButton>

      <template #footer>
        <IxButton
          submit large
          @click="uploadAndInterpolate"
        >
          <IxRes>phbb.upload.upload</IxRes>
        </IxButton>
      </template>
    </ContentWrapper>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'
import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import BoolSwitch from 'src/vue/components/forms/BoolSwitch'
import BsRadio from 'src/vue/components/bootstrap/BsRadio'
import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'
import NumericInput from 'src/vue/components/forms/NumericInput'
import IxButton from 'src/vue/components/IxButton'
import GeometryImportModal from '@components/forms/GeometryImportModal'
import HelpBox from '@components/help/HelpBox'
import ContentWrapper from '@frs/components/phbb/widgets/ContentWrapper'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

import AttributeSelection from './AttributeSelection'
import UploadMapLayer from '../widgets/UploadMapLayer'

export default {
  components: {
    BoolSwitch,
    BsRadio,
    VueDateTimePicker,
    NumericInput,
    IxButton,
    GeometryImportModal,
    HelpBox,
    ContentWrapper,
    LabelAndMessage,
    FrsRouterBackLink,
    UploadMapLayer,
    AttributeSelection
  },
  mixins: [StandardRulesMixin, FormPartMixin, MirrorRouteMixin],
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.common.entireField',
      'data.upload.mappingDate',
      'data.upload.method',
      'data.upload.blockSize',
      'data.upload.pixelSize',
      'data.upload.sensorData'
    ]),
    sensorDataFileName () {
      if (this.sensorData && this.sensorData.features && this.sensorData.features.length > 0) {
        this.$set(this.fieldStates, 'fileSelected', undefined)
        return `${this.sensorData.features[0].properties.filename}.shp`
      } else return ''
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/phbb', [
      'uploadAndInterpolateSensorData'
    ]),
    async uploadAndInterpolate () {
      this.validate()
      this.validateFileSelection()
      await this.$nextTick()
      if (this.state !== 'success') return

      notifications.info(this.$i18n.translate('phbb.upload.notifications.uploadInProgress'))
      try {
        const result = await this.uploadAndInterpolateSensorData()
        if (result.success) {
          notifications.success(this.$i18n.translate('phbb.upload.notifications.success'))
        } else {
          notifications.warning(this.$i18n.translate('phbb.upload.notifications.noMapsCreated'))
          console.error(result.rLog)
        }
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.upload.notifications.error'))
        console.error(error)
      }
    },
    onLoad (geojson) {
      this.sensorData = geojson
    },
    validateFileSelection () {
      this.$set(this.fieldStates, 'fileSelected', this.sensorDataFileName ? 'success' : 'error')
    },
    launchMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
